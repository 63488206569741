import * as React from 'react'
import { FlatfileButton } from '@flatfile/react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'

import Layout from '../components/layout'

const IndexPage = () => {
    const contentfulConfig = {
        spaceId: process.env.CONTENTFUL_SPACE_ID,
        accessToken: process.env.CONTENTFUL_MANAGEMENT_TOKEN,
        host: process.env.CONTENTFUL_HOST,
        localeFilter: (locale) => locale.code === 'en-US',
    }

    const { spaceId, accessToken } = contentfulConfig

    const isSSR = typeof window === 'undefined'

    const data = useStaticQuery(graphql`
        {
            allContentfulCategory {
                edges {
                    node {
                        title
                        id
                        contentful_id
                    }
                }
            }
            allContentfulLocation {
                edges {
                    node {
                        title
                        id
                        contentful_id
                    }
                }
            }
            allContentfulPostalCode {
                edges {
                    node {
                        id
                        contentful_id
                        postalCode
                    }
                }
            }
            allContentfulServiceArea {
                edges {
                    node {
                        id
                        contentful_id
                        title
                    }
                }
            }
            allContentfulServiceGroup {
                edges {
                    node {
                        id
                        contentful_id
                        title
                    }
                }
            }
            allContentfulServiceProvider {
                edges {
                    node {
                        title
                        id
                        contentful_id
                    }
                }
            }
        }
    `)

    // Locations can have multiple service groups
    const locations = get(data, 'allContentfulLocation.edges')
    const postalCodes = get(data, 'allContentfulPostalCode.edges')
    const serviceAreas = get(data, 'allContentfulServiceArea.edges')
    const serviceGroups = get(data, 'allContentfulServiceGroup.edges')
    const serviceProviders = get(data, 'allContentfulServiceProvider.edges')

    return (
        <Layout>
            <h1>Import</h1>
        </Layout>
    )
}

export default IndexPage
